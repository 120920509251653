<template>
    <div class="header_chart">
        <span class="span_factory">{{this.$t('All.厂区')}}</span>
        <el-select v-model="value" :placeholder="this.$t('All.请选择')" size="small" @change="Selectfactory">
            <el-option
            v-for="item in factoryoption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>
        <span class="span_date">{{this.$t('All.日期区间')}}</span>
        <el-date-picker
          v-model="value1"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="~"
          :start-placeholder="this.$t('All.开始日期')"
          :end-placeholder="this.$t('All.结束日期')"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions" size="small" @change="sumbut">
        </el-date-picker>
    </div>
</template>

<script>
export default {
  props: ['sumbut', 'Selectfactory'],
  data () {
    return {
      factoryoption: [{
        value: 'TC',
        label: '太仓厂'
      }, {
        value: 'ZH',
        label: '珠海厂'
      }, {
        value: 'VN',
        label: '越南厂'
      }],
      value: '',
      value1: '',
      pickerOptions: {
        shortcuts: [{
          text: this.$t('All.最近一周'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('All.最近一个月'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('All.最近三个月'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  created () {
    this.value1 = [this.formatDate(new Date()), this.formatDate(new Date())]
  },
  methods: {
    // 格式化函数，确保月份和日期为两位数
    formatDate (date) {
      const y = date.getFullYear()
      const m = (date.getMonth() + 1).toString().padStart(2, '0') // 月份加 1，确保两位数
      const d = date.getDate().toString().padStart(2, '0') // 确保日期是两位数
      return `${y}-${m}-${d}`
    }
  }
}
</script>
<style lang="less" scoped>
.header_chart{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    color: #595959;
}
.el-select {
    margin-right: 10px;
}
.el-button {
    margin-left: 10px;
}
.span_factory {
    margin-left: 5px;
    margin-right: 5px;
}
.span_date {
    margin-right: 5px;
}
</style>
